import logo from './logo.svg';
import './App.css';
import Cookies from 'js-cookie';
import React, {useRef, useEffect, useState} from 'react';

import Map from './Map.js'
import Map2 from './map2.js'
import store from './store';
import {Provider} from 'react-redux';
class App extends React.Component {


    constructor(props) {
        let environment = require('./environments');
        super(props);
        this.state = {
            refresh_token: Cookies.get('refresh_token'),
            access_token: null,
            errors: null,
            getting_athlete: false,
            getting_status: false,
            authenticating: false,
            athlete: null,
            environment: environment,
            status: null,
            registering:false,
            refreshing:false
        };

        let code = new URLSearchParams(window.location.search).get('code')
        if (code) {
            Cookies.set('refresh_token', code);
            this.state.refresh_token = code;
        }
        this.intervalExpired = this.intervalExpired.bind(this)

    }

    authenticate(refresh_token) {
        if (!this.state.authenticating) {
            fetch('https://www.strava.com/api/v3/oauth/token', {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'multipart/form-data'
                }),
                body: 'client_id=4431&client_secret=1f1e200fc6cf162f08d33205b4b7ea736e9416c7&refresh_token=a9eb867644d20c9adbfbe5bee58c5ec4c6cdc628&grant_type=refresh_token'
            })
                .then(response => response.json())
                .then((data) => {
                    this.setState((state) => {
                            return {
                                refresh_token: data.refresh_token,
                                access_token: data.access_token,
                                errors: state.errors,
                                getting_athlete: state.getting_athlete,
                                getting_status: state.getting_status,
                                authenticating: false,
                                athlete: state.athlete,
                                status: state.status
                            };
                        }
                    );


                    this.get_athlete(data.access_token);

                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.setState({authenticating: false});
                    this.setState({errors: error});
                });

        }
    }

    render() {

        if (this.state.status != null && this.state.status.hexmapLoaded === "false") {
            return this.renderConsole();
        }

        if (this.state.refresh_token == null || this.state.errors != null  ) {
            return this.renderLogin();
        } else {
            if (this.state.refresh_token != null && this.state.access_token == null) {

                this.authenticate(this.state.refresh_token);

            }
            //
            //
            //return this.renderKepler();
            return this.renderMap();
        }
    }

    get_status(access_token) {

        console.log(`Getting status ${access_token}`);

        if (!this.state.getting_status && access_token != null && this.state.athlete!= null && this.state.athlete.sourceId != null) {

            this.setState({getting_status: true});

            let base = process.env.REACT_APP_URL_BASE;
            if (base == null) {
                base = ""
            }
            fetch(`${base}/rides/athlete/${this.state.athlete.sourceId}/status/`, {
                headers: {
                    'Content-Type': 'none',
                }
            })
                .then(res => res.json())
                .then((data) => {
                    //console.log(data)

                    this.setState((state) => {
                        return {
                            refresh_token: state.refresh_token,
                            access_token: state.access_token,
                            errors: state.errors,
                            getting_athlete: false,
                            authenticating: false,
                            getting_status: false,
                            athlete: state.athlete,
                            status: data
                        };


                    });
                    console.log(`Status  ${JSON.stringify(data)}`);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.setState({getting_status: false});
                    this.setState({errors: error});
                });
        }
    }

    get_athlete(refresh_token) {
        console.log(`get_athlete ${this.state.getting_athlete} ${refresh_token}`);
        if (!this.state.getting_athlete && refresh_token != null) {

            let base = process.env.REACT_APP_URL_BASE;
            if (base == null) {
                base = ""
            }
            console.log(`Fetching ${base}/rides/athlete`,);
            fetch(`${base}/rides/athlete`, {
                headers: {
                    'Content-Type': 'application/json',
                    'AuthKey': refresh_token
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else if(response.status === 404) {
                        this.register_athlete(this.state.access_token, this.state.refresh_token);
                        return '{}'
                    } else {
                        return Promise.reject('some other error: ' + response.status)
                    }
                })
                .then((data) => {
                    console.log(data)

                    this.setState((state) => {
                        return {
                            refresh_token: state.refresh_token,
                            access_token: state.access_token,
                            errors: state.errors,
                            getting_athlete: false,
                            authenticating: false,
                            getting_status: false,
                            athlete: data,
                            status: null
                        };


                    });
                    console.log(`Athlete  ${JSON.stringify(data)}`);

                    this.get_status(this.state.access_token);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.setState({getting_athlete: false});
                    this.setState({errors: error});
                });
        }
    }


    renderLogin() {

        return (
            <div id="login">
                <form method="GET" encType="multipart/form-data" action="https://www.strava.com/oauth/authorize">
                    <input type="hidden" name="client_id" value="4431"/>
                    <input type="hidden" name="redirect_uri" id="redirect_uri" value={window.location.href}/>
                    <input type="hidden" name="response_type" value="code"/>
                    <input type="hidden" name="approval_prompt" value="force"/>
                    <input type="hidden" name="scope" value="activity:read_all"/>
                    <input type="submit" value="Login with Strava"/>
                </form>
            </div>
        );
    }

    renderConsole() {

        //if( !this.state.refreshing) {
           // this.setPolling();
        //}
        return (<div id="console">
            <div>
                <div><h3>Loading your Data</h3></div>
                <div>

                    <p>Total Activities:

                        {this.state.status.activitiesLoaded}</p>

                </div>
                <div>

                    <p>Activities to load:

                        {this.state.status.activitiesWithoutSegments}</p>

                </div>
                <div>

                    <p>Jobs Running:

                        {this.state.status.runningJobs}</p>

                </div>
                <div>

                    <p>Heatmap Generated:

                        {this.state.status.hexmapLoaded}</p>
                </div>
            </div>
        </div>);
    }


    renderMap() {
        //<!--Map athlete_id={this.state.athlete.sourceId} lat={this.state.athlete.averageLatitude} lon={this.state.athlete.averageLongitude}/-->
        //<Map athlete_id={this.state.athlete.sourceId} lat='53.4487' lon='-2.3747'/>
        if (this.state.athlete != null && this.state.athlete.sourceId != null) {
            //console.log(JSON.stringify(this.state.athlete));
            //console.log( `Location ${this.state.athlete.averageLatitude}, ${this.state.athlete.averageLongitude}`)

            return (
                <div id="map">
                    <Map athlete_id={this.state.athlete.sourceId} lat={this.state.athlete.averageLatitude}
                         lon={this.state.athlete.averageLongitude}/>
                </div>
            );
        } else {

            return (
                <div id="map">
                    <p>Loading...</p>
                </div>
            );
        }
    }

    renderKepler() {
        return (  <Provider store={store}>
            <Map2 />
        </Provider>);
    }
    register_athlete(access_token, refresh_token) {
        console.log('Registering')
        if (!this.state.registering) {

            this.setState({registering:true})
            let base = process.env.REACT_APP_URL_BASE;
            if (base == null) {
                base = ""
            }

            fetch(`${base}/rides/athlete/${refresh_token}`, {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'none',
                    'AuthKey': access_token
                }),
                body: ''
            })
                .then(response => response.json())
                .then((data) => {
                    console.log(`Registration ${JSON.stringify(data)}`);
                    this.setState((state) => {
                            return {
                                refresh_token: refresh_token,
                                access_token: access_token,
                                errors: state.errors,
                                getting_athlete: state.getting_athlete,
                                getting_status: state.getting_status,
                                authenticating: false,
                                athlete: state.athlete,
                                status: state.status,
                                registering:false
                            };
                        }

                    );

                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.setState({registering: false});
                    this.setState({errors: error});
                });
        }
    }
    componentDidMount() {
        console.log('componentDidMount')
        setInterval( this.intervalExpired, 10000);
    }

    intervalExpired() {
        if( this.state.status != null && this.state.status.hexmapLoaded === "false") {
            console.log('intervalExpired');
            //window.location.reload(false);
            this.setState({errors: null});
            this.get_status(this.state.access_token);
        }
    }
}

export default App;
