import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './Map.css';

mapboxgl.accessToken =
    'pk.eyJ1IjoibWFya2hvbGNyb2Z0IiwiYSI6ImNrbDJhejV2NzEzMTMydmxiNXVtOG52cmUifQ.OQBJomLim2zw5gzV9jVkiQ';

const Map = (props) => {

    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [props.lon, props.lat],
            zoom: 5
        });

        map.on('load', () => {
            console.log(JSON.stringify(props));

            let base = process.env.REACT_APP_URL_BASE;
            if (base == null) {
                base = ""
            }

            map.addSource('hexmap', {
                type: 'geojson',
                data: `${base}/rides/athlete/${props.athlete_id}/hexmap/0`
            });
            map.addSource('heatmap', {
                type: 'geojson',
                data: `${base}/rides/athlete/${props.athlete_id}/hexmap/1`
            });
            map.addControl(new mapboxgl.NavigationControl());
            map.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
// When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: true,
// Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: true
                })
            );

            map.setLayoutProperty('country-label', 'text-field', [
                'format',
                ['get', 'name_en'],
                { 'font-scale': 1.2 },
                '\n',
                {},
                ['get', 'name'],
                {
                    'font-scale': 0.8,
                    'text-font': [
                        'literal',
                        ['DIN Offc Pro Italic', 'Arial Unicode MS Regular']
                    ]
                }
            ]);

            map.addLayer(
                {
                    id: 'hexmap',
                    type: 'fill',
                    source: 'hexmap',
                    layout: {},
                    paint:{
                       'fill-color':  '#008',
                        'fill-opacity': 0.2
                    }
                },
                'country-label'
            );
            map.addLayer(
                {
                    id: 'heatmap',
                    type: 'fill',
                    source: 'heatmap',
                    layout: {},
                    paint:{
                        'fill-color':  '#00f',
                        'fill-opacity': 0.2
                    }
                },
                'country-label'
            );
            setMap(map);
        });

        // Clean up on unmount
        return () => map.remove();
    }, []);


    return (
        <div>
            <div ref={mapContainerRef} className='map-container' />
        </div>
    );
};

export default Map;